<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-select v-model="search.schema" clearable placeholder="终端">
            <el-option
              v-for="item,key in applist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input @keyup.enter="Search()" v-model.trim="search.uid" placeholder="用户uid"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input @keyup.enter="Search()" v-model.trim="search.account" placeholder="用户账号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input @keyup.enter="Search()" v-model.trim="search.nickname" placeholder="用户昵称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input @keyup.enter="Search()" v-model.trim="search.tuya_uid" placeholder="涂鸦uid"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="Search">查询</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="addcountry">增加</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list" :empty-text="emptyText">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="用户uid">
        <template v-slot="scope">
          <span>{{ scope.row.uid }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户账号">
        <template v-slot="scope">
          <span>{{ scope.row.account }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户昵称">
        <template v-slot="scope">
          <span>{{ scope.row.nickname }}</span>
        </template>
      </el-table-column>
      <el-table-column label="区域">
        <template v-slot="scope">
          <span>{{ scope.row.region }}</span>
        </template>
      </el-table-column>
      <el-table-column label="终端">
        <template v-slot="scope">
          <span>{{ appName[scope.row.schema] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="国家码">
        <template v-slot="scope">
          <span>{{ scope.row.country }}</span>
        </template>
      </el-table-column>
      <el-table-column label="时区码">
        <template v-slot="scope">
          <span>{{ scope.row.zone }}</span>
        </template>
      </el-table-column>
      <el-table-column label="注册时间">
        <template v-slot="scope">
          <span>{{ ChangeTime(scope.row.create_time) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <template v-slot="scope">
          <router-link style="margin-right:10px;" :to="'/iot/userinfo/' + scope.row.uid +'&'+ scope.row.region">
            <el-button type="info" size="small">详情</el-button>
          </router-link>
          <el-button type="danger" size="small" @click="deleteUser(scope.row.uid)">删除</el-button>
          <!-- <router-link style="margin-left:10px" :to="'/iot/user/device/'+ scope.row.uid + '&' + scope.row.schema+'&'+ scope.row.region">
            <el-button type="info" size="small">绑定设备</el-button>
          </router-link> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 新增修改 -->
    <el-dialog :title="title+'用户管理'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <!-- <el-form-item label="用户标识" prop="uid" label-width="120px">
          <el-input v-model="form.uid" autocomplete="off"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="区域" prop="region" label-width="120px">
          <el-input v-model="form.region" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item label="终端" prop="schema" label-width="120px">
          <el-select v-model="form.schema">
            <el-option
              v-for="item in applist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="国家区号" prop="country" label-width="120px">
          <el-select v-model="form.country">
            <el-option
              v-for="item in countrylist"
              :key="item.code"
              :label="item.chinese"
              :value="item.code+''">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="用户昵称" label-width="120px">
          <el-input v-model="form.nickname" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item label="用户账号" prop="account" label-width="120px">
          <el-input v-model="form.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="用户密码" prop="password" label-width="120px">
          <el-input v-model="form.password" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="用户所在时区" label-width="120px">
          <el-select v-model="form.zone" clearable>
            <el-option
              v-for="item in zonelist"
              :key="item.code"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
          <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import util from '../../../util/util'
export default {
  name: 'user',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      zonelist: [
        {code: 0,name: '零时区'},
        {code: 1,name: '东一区'},
        {code: 2,name: '东二区'},
        {code: 3,name: '东三区'},
        {code: 4,name: '东四区'},
        {code: 5,name: '东五区'},
        {code: 6,name: '东六区'},
        {code: 7,name: '东七区'},
        {code: 8,name: '东八区'},
        {code: 9,name: '东九区'},
        {code: 10,name: '东十区'},
        {code: 11,name: '东十一区'},
        {code: -1,name: '西一区'},
        {code: -2,name: '西二区'},
        {code: -3,name: '西三区'},
        {code: -4,name: '西四区'},
        {code: -5,name: '西五区'},
        {code: -6,name: '西六区'},
        {code: -7,name: '西七区'},
        {code: -8,name: '西八区'},
        {code: -9,name: '西九区'},
        {code: -10,name: '西十区'},
        {code: -11,name: '西十一区'},
      ],
      title: '',
      search: {
        page: 1,
        limit: 10,
        schema: '',
        uid: '',
        account: '',
        nickname: '',
        tuya_uid: ''
      },
      list: [],
      total: 0,
      dialogFormVisible: false,
      form: {
        // uid: '',
        account: '',
        // region: '',
        schema: '',
        country: '',
        // nickname: '',
        password: '',
        // zone: 0,
      },
      rules: {
        uid: [{ required: true, message: '请输入用户标识', trigger: 'blur' }],
        account: [{ required: true, message: '请输入用户账号', trigger: 'blur' }],
        region: [{ required: true, message: '请输入区域', trigger: 'blur' }],
        schema: [{ required: true, message: '请输入终端', trigger: 'blur' }],
        country: [{ required: true, message: '请输入国家区号', trigger: 'blur' }],
        nickname: [{ required: true, message: '请输入用户昵称', trigger: 'blur' }],
        password: [{ required: true, message: '请输入用户密码', trigger: 'blur' }],
        zone: [{ required: true, message: '请输入用户所在时区', trigger: 'blur' }],
      },
      edit: false,
      appName: {},
      applist: [],
      countrylist: [],
      emptyText: '请按条件查询'
    }
  },
  computed: {},
  watch: {},
  methods: {
    GetCountry () {
      this.api.CountryList({page: 1,
        limit: 100,}).then(res => {
        if (res.data.code == 200) {
          this.countrylist = res.data.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Getapp () {
      this.api.ClientList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          this.applist = res.data.data.data
          res.data.data.data.forEach(item => {
            this.appName[item.code] = item.name
          })
          this.GetCountry()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    changetime (item) {
      return util.dateFormat(item)
    },
    handleCurrentChange(val) {
      this.search.page = val
      sessionStorage.setItem('currentPage',val);
      this.Search()
    },
    Search() {
      this.api.UserManageList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          if (res.data.data.data.length == 0) {
            this.emptyText = 'No Data'
          }
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    addcountry () {
      this.form = {
        // uid: '',
        account: '',
        // region: '',
        schema: '',
        country: '',
        // nickname: '',
        password: '',
        // zone: this.form.zone,
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.title = '新增'
      this.edit = false
      this.dialogFormVisible = true
    },
    editUser(item) {
      this.edit = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.title = '修改'
      this.api.UserManageDetail({
        uid: item.uid
      }).then(res => {
         if (res.data.code == 200) {
          this.dialogFormVisible = true
          this.form = res.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    deleteUser(item) {
      this.$confirm('是否删除该用户管理?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.UserManageDelete({
          uid: item
        }).then(res => {
          if (res.data.code == 200) {
            this.Search()
            this.$message.success('删除成功')
            return
          }
          this.$message.error('删除失败'+ res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    addconfirm () {
      this.update()
    },
    update (){
      this.api.UserManageUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.Search()
          this.$message.success(this.title + '成功')
          this.dialogFormVisible = false
          return
        }
        this.$message.error(this.title + '失败' + res.data.msg)
      })
    },
    editconfirm () {
      this.update()
    },
    ChangeTime (item) {
      return util.dateFormat(item)
    },
    getSchoolWebModuleMessageListFunc(item){
      setTimeout(() => {
        if(sessionStorage.getItem("currentPage") && sessionStorage.getItem("currentPage") !== 1){
          this.search.page = Number(sessionStorage.getItem("currentPage"))
        }
        if (Number(sessionStorage.getItem("currentPage"))) {
          this.Search()
        }
      },0)
    }
  },
  filters: {},
  mounted () {
    this.getSchoolWebModuleMessageListFunc()
  },
  created () {
    this.Getapp()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
